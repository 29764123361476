html {
  background-color: #111;
}

.web-capture-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.video-base {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}

.video-area canvas {
  max-width: 100%;
  height: auto;
}

.settings {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 32px;
  width: auto;
  height: auto;
  margin: 16px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 24px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #777;
}

select {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 24px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="rgba(255, 255, 255, 0.5)"><polygon points="0,1 8,1 4,6"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 20px;  /* make sure text doesn't overlap the arrow */
}

select option {
  background-color: #333;
  color: #fff;
}

.status {
  display: block;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  margin-top: 0;
}

.about {
  color: white;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.2s ease;
  align-items: center;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-bottom: 16px;
}

.about:hover {
  opacity: 1;
}
